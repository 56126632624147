<template>
    <div class="dropdown">
      <button @click="toggleDropdown()" class="dropdown-button">...</button>
      <div v-if="isDropdownOpen" class="dropdown-content">
        <a v-for="item in items" :key="item.id" @click="selectItem(item.value)">
            <DeleteItem v-if="selectedItemDropdown" :selectedItemDropdown="selectedItemDropdown" :id="id"/>
          {{ item.text }}
        </a>
      </div>
    </div>
  </template>
  
  <script>
  import DeleteItem from './DeleteItem.vue';                                                                                                                 
  export default {
    name:DropdownList,
    props: ['id'],
    data() {
      return {
        isDropdownOpen: false,
        listid:null,
        items: [                                                                                                                                                                        
          { id: 1, value: 'delete', text: '1-delete' },
          { id: 2, value: 'iupdate', text: '2-update' },
          { id: 3, value: 'more', text: '3-more' }
        ],
        selectedItemDropdown: '',
       
      };
    },
    methods: {
      toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
      },
      selectItem(value) {
        this.selectedItemDropdown= value;
        this.isDropdownOpen = false;
       
      }
    },
    components:{
        DeleteItem,
    }
  };
  </script>
  
  <style scoped>
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: block;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  </style>