<template>
  <p>{{ actionName}}</p>
</template>

<script>
export default {
    name:'DeleteItem',
    props:[id,selectedItemDropdown],
data(){
    return{
        actionName:this.selectedItemDropdown,
    }
}
}
</script>

<style>

</style>